import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Box,
    Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TreatmentPath from './TreatmentPath';

const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            marginTop: '3%',
          },
        },
      },
    },
  });


const TreatmentContainer = ({ title }) => (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'center', m: 0, marginLeft: '5%' }}>{title}</Typography>
        <ThemeProvider theme={theme}>
        <Button variant="contained" value={title} onClick={() => onClickPredict(title)}>분석</Button>
        </ThemeProvider>
    </Box>
    <TreatmentPath />
  </>
);

export default TreatmentContainer;