import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
    id: String,
    NeurologicalSystem: string,
    RespiratorySystem: string,
    CardiovascularSystem: string,
    LiverFunction: string,
    RenalFunction: string,
    Coagulation: string
) {
  return { id, NeurologicalSystem, RespiratorySystem, CardiovascularSystem, LiverFunction, RenalFunction, Coagulation };
}

const rows = [
  createData('id','11', '410', '69(MAP)', '1.1', '1.1', '50')
];
const tableStyle = {
  // backgroundColor: '#f5f5f5', // 연한 회색 배경색
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
};

const cellStyle = {
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
  fontSize: '14px'
};

const paperStyle = {
  boxShadow: 'none', // 그림자 효과 제거
};

export default function Sofa() {
  return (
    <TableContainer component={Paper} sx={{ ...tableStyle, ...paperStyle }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: '#d2d2d2', color: '#181d27' }}>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>GCS</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>PaO2/FiO2</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Hypotension</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>T.Bil</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Cr</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Platelet</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={cellStyle}
              align="center"
            >
              <TableCell align="center" component="th" scope="row" sx={cellStyle}>{row.NeurologicalSystem}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.RespiratorySystem}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.CardiovascularSystem}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.LiverFunction}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.RenalFunction}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.Coagulation}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
