import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
    SystolicBloodPressure: string,
    HeartRate: string,
    RespiratoryRate: string,
    Temperature : string,
    AVPUScale : string,
) {
  return { SystolicBloodPressure, HeartRate, RespiratoryRate, Temperature, AVPUScale };
}

const rows = [
  createData('22', '101', '101', '37.5', 'A')
];
const tableStyle = {
  // backgroundColor: '#f5f5f5', // 연한 회색 배경색
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
};

const cellStyle = {
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
  fontSize: '14px'
};

const paperStyle = {
  boxShadow: 'none', // 그림자 효과 제거
};

export default function Mews() {
  return (
    <TableContainer component={Paper} sx={{ ...tableStyle, ...paperStyle }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: '#d2d2d2', color: '#181d27' }}>
          <TableRow>
            <TableCell align="center" sx={cellStyle}>Respirations</TableCell>
            <TableCell align="center" sx={cellStyle}>Systolic BP</TableCell>
            <TableCell align="center" sx={cellStyle}>Heart Rate</TableCell>
            <TableCell align="center" sx={cellStyle}>Temperature</TableCell>
            <TableCell align="center" sx={cellStyle}>Consciousness</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.gcs}
              sx={cellStyle}
              align="center"
            >
                {/* RespiratoryRate, OxygenSaturation, SystolicBloodPressure, HeartRate, LevelofConsciousness, Temperature */}
              <TableCell align="center" component="th" scope="row" sx={cellStyle}>{row.SystolicBloodPressure}</TableCell>
              <TableCell align="center" sx={cellStyle}>{row.HeartRate}</TableCell>
              <TableCell align="center" sx={cellStyle}>{row.RespiratoryRate}</TableCell>
              <TableCell align="center" sx={cellStyle}>{row.Temperature}</TableCell>
              <TableCell align="center" sx={cellStyle}>{row.AVPUScale}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
