import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/system';

const CustomHeader = styled('div')({
  backgroundColor: '#d2d2d2',
  color: '#181d27',
  textAlign: 'center',
});

const columns: GridColDef[] = [
  {
    field: 'patno',
    headerName: 'patno',
    width: 250,
    editable: false,
    headerClassName: 'super-app-theme--header',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'name',
    headerName: 'name',
    width: 250,
    editable: false,
    headerClassName: 'super-app-theme--header',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'age',
    headerName: 'age',
    width: 220,
    editable: false,
    headerClassName: 'super-app-theme--header',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'sex',
    headerName: 'sex',
    width: 210,
    editable: false,
    headerClassName: 'super-app-theme--header',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'meddate',
    headerName: 'meddate',
    width: 200,
    editable: false,
    headerClassName: 'super-app-theme--header',
    align: 'center',
    headerAlign: 'center',
  },
];


const DataTable = ({ selectRow, inputValue }) => {
  const [rows, setRows] = React.useState([
    {'id':2,'name':"이상민",'patno':11775,'age':55,'sex':'M','meddate':'2023-11-01','MedicalDepartment': 'GI', 'InpatientWard':'ICU','LengthofStay':'7days', 'AttendingPhysician': '정윤정', 'Diagnosis':'Sepsis'},
    {'id':3,'name':"김철수",'patno':12359,'age':51,'sex':'M','meddate':'2023-10-07','MedicalDepartment': 'GI', 'InpatientWard':'SurgeryInpatient Ward','LengthofStay':'3days', 'AttendingPhysician': '정윤정', 'Diagnosis':'Post-traumatic Sepsis'},
    {'id':5,'name':"이영희",'patno':10571,'age':45,'sex':'F','meddate':'2023-11-07','MedicalDepartment': 'GI', 'InpatientWard':'NeurologyWard','LengthofStay':'5days', 'AttendingPhysician': '정윤정', 'Diagnosis':'Sepsis'},
    {'id':4,'name':"박영희",'patno':13597,'age':60,'sex':'F','meddate':'2023-10-30','MedicalDepartment': 'GI', 'InpatientWard':'TraumaCenter','LengthofStay':'11days', 'AttendingPhysician': '정윤정', 'Diagnosis':'Septicemia'},
  ]);

  const [filteredRows, setFilteredRows] = React.useState(rows);

  React.useEffect(() => {
    const filtered = rows.filter(row => {
      const patnoIncludes = row.patno && row.patno.toString().includes(inputValue);
      const nameIncludes = row.name && row.name.toString().toLowerCase().includes(inputValue.toLowerCase());
      return patnoIncludes || nameIncludes;
    });

    setFilteredRows(filtered);
  }, [rows, inputValue]);

  React.useEffect(() => {
    setFilteredRows(rows);
  }, []);

  const handleRowClick = (params) => {
    selectRow(params.row);
  };

  React.useEffect(() => {
    // 모달이 닫힐 때 초기화
    if (!inputValue) {
      setFilteredRows(rows);
    }
  }, [inputValue, rows]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: '#d2d2d2',
            color: '#181d27'
          },
        }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default DataTable;
