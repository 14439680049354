import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Esofa from './Indicators/Esofa';
import Sofa from './Indicators/Sofa';
import Apacheii from './Indicators/Apacheii';

const tabPanelStyle = {
  // padding: '16px',
};

const tabStyles = {
  'Item One': {
    backgroundColor: '42424a',
    color: 'white', // 텍스트 색상을 흰색(white)으로 지정
  },
  'Item Two': {
    backgroundColor: '42424a',
    color: 'white', // 텍스트 색상을 흰색(white)으로 지정
  },
  'Item Three': {
    backgroundColor: '42424a',
    color: 'white', // 텍스트 색상을 흰색(white)으로 지정
  },
  // 추가 스타일을 필요한 탭에 적용
  'Colored Text Tab': {
    fontWeight: 'bold', // 볼드 스타일 적용
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={tabPanelStyle}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// ...
// ...

export default function IndicatorScore() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'center' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={(
              <span style={{ fontWeight: value === 0 ? 'bold' : 'none', color: value === 0 ? 'white' : 'black' }}>
                eSOFA <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 24, color: value === 0 ? 'white' : 'black' }}>2</Typography>
              </span>
            )}
            {...a11yProps(0)}
            sx={{
              ...tabStyles['Item Three'],
              backgroundColor: value === 0 ? '#14a69f' : 'white',
            }}
          />
          <Tab
            label={(
              <span style={{ fontWeight: value === 1 ? 'bold' : 'none', color: value === 1 ? 'white' : 'black' }}>
                SOFA <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 24, color: value === 1 ? 'white' : 'black' }}>24</Typography>
              </span>
            )}
            {...a11yProps(1)}
            sx={{
              ...tabStyles['Item Three'],
              backgroundColor: value === 1 ? '#14a69f' : 'white',
            }}
          />
          <Tab
            label={(
              <span style={{ fontWeight: value === 2 ? 'bold' : 'none', color: value === 2 ? 'white' : 'black' }}>
                APACHE II <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 24, color: value === 2 ? 'white' : 'black' }}>16</Typography>
              </span>
            )}
            {...a11yProps(2)}
            sx={{
              ...tabStyles['Item Three'],
              backgroundColor: value === 2 ? '#14a69f' : 'white',
            }}
          />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={value} key={1} index={0}>
          <Esofa />
        </CustomTabPanel>
        <CustomTabPanel value={value} key={2} index={1}>
          <Sofa />
        </CustomTabPanel>
        <CustomTabPanel value={value} key={3} index={2}>
          <Apacheii />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

