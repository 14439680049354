import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Card from "@mui/material/Card";

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 40 },

  {
    field: 'in_time',
    headerName: 'in_time',
    width: 150,
    editable: false,
  },
  {
    field: 'ou_time',
    headerName: 'ou_time',
    width: 150,
    editable: false,
  },
  // 'in_time', 'ou_time', 'inno', 'death_dt', 'total_treat_days',
  {
    field: 'inno',
    headerName: 'inno',
    width: 80,
    editable: false,
  },
  {
    field: 'death_dt',
    headerName: 'death_dt',
    width: 150,
    editable: false,
  },
  {
    field: 'duration_days',
    headerName: 'duration_days',
    width: 110,
    editable: false,
  },
  // 'in_time', 'ou_time', 'inno', 'death_dt', 'total_treat_days', 'duration_days', 'treatment_day1', 'treatment_day10', 'treatment_day30', 'treatment_day60', '1day', '10day', '30day','60day'
  {
    field: 'total_treat_days',
    headerName: 'total_treat_days',
    width: 110,
    editable: false,
  },
  {
    field: 'treatment_day1',
    headerName: 'treatment_day1',
    width: 220,
    editable: false,
  },
  {
    field: 'treatment_day10',
    headerName: 'treatment_day10',
    width: 220,
    editable: false,
  },
  {
    field: 'treatment_day30',
    headerName: 'treatment_day30',
    width: 220,
    editable: false,
  },
  {
    field: 'treatment_day60',
    headerName: 'treatment_day60',
    width: 220,
    editable: false,
  },
  {
    field: '1day',
    headerName: '1day',
    width: 110,
    editable: false,
  },
  {
    field: '10day',
    headerName: '10day',
    width: 110,
    editable: false,
  },
  {
    field: '30day',
    headerName: '30day',
    width: 110,
    editable: false,
  },
  {
    field: '60day',
    headerName: '60day',
    width: 110,
    editable: false,
  }
];

const rows = [

  // { id: 1, in_time: '2012-01-27 17:23', ou_time: '2012-02-24 22:59', inno:'5', death_dt:'2012-02-24 22:01', duration_days:'27' },
  // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  {"id":0,"in_time":"2012-01-27 17:23:00","ou_time":"2012-02-24 22:59:53","inno":5,"death_dt":"2012-02-24 22:01:00","total_treat_days":29,"duration_days":29,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B Albumin ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":1,"in_time":"2012-08-30 16:08:00","ou_time":"2012-11-04 14:04:00","inno":18,"death_dt":"2012-11-04 04:17:00","total_treat_days":66,"duration_days":66,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Albumin ANTI_B Fluid Albumin Fluid ANTI_B VASO ANTI_B Analgesics Fluid Sedative","treatment_day30":"Sedative Analgesics ANTI_B Albumin ANTI_B","treatment_day60":"Fluid ANTI_B Fluid Analgesics ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":2,"in_time":"2017-08-09 16:32:00","ou_time":"2017-08-26 09:42:53","inno":23,"death_dt":"2017-08-26 08:36:00","total_treat_days":18,"duration_days":17,"treatment_day1":"Fluid ANTI_B","treatment_day10":"ANTI_B NM_Blocker ANTI_B Albumin Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":3,"in_time":"2011-03-23 16:33:00","ou_time":"2011-04-14 09:20:54","inno":62,"death_dt":"2011-04-14 01:20:00","total_treat_days":19,"duration_days":22,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Trans ANTI_B Analgesics Fluid Trans Albumin Analgesics ANTI_B Trans Albumin Trans Analgesics ANTI_B Analgesics","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":4,"in_time":"2010-06-24 15:03:00","ou_time":"2010-07-05 13:36:50","inno":6526,"death_dt":"2010-07-05 10:45:00","total_treat_days":12,"duration_days":11,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B Steroid ANTI_B Trans ANTI_B VASO","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":5,"in_time":"2010-04-18 16:21:00","ou_time":"2010-05-13 12:30:26","inno":67,"death_dt":"2010-05-13 10:45:00","total_treat_days":24,"duration_days":25,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":6,"in_time":"2015-07-15 19:42:00","ou_time":"2015-07-22 08:30:18","inno":6628,"death_dt":"2015-07-22 08:30:00","total_treat_days":8,"duration_days":7,"treatment_day1":"Steroid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":7,"in_time":"2016-12-04 14:50:00","ou_time":"2016-12-15 12:29:43","inno":74,"death_dt":"2016-12-15 10:18:00","total_treat_days":11,"duration_days":11,"treatment_day1":"No treatment found on the specified date","treatment_day10":"NaHCO3 Trans Fluid Trans ANTI_B NaHCO3 Trans Albumin Fluid Trans ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":8,"in_time":"2017-12-22 15:54:00","ou_time":"2018-01-10 10:00:14","inno":75,"death_dt":"2018-01-10 05:05:00","total_treat_days":19,"duration_days":19,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Albumin Analgesics ANTI_B VASO Fluid Sedative ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":9,"in_time":"2018-02-04 15:59:00","ou_time":"2018-02-27 23:52:46","inno":86,"death_dt":"2018-02-27 22:45:00","total_treat_days":24,"duration_days":24,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Albumin ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":10,"in_time":"2012-08-08 15:30:00","ou_time":"2012-08-18 10:08:36","inno":7949,"death_dt":null,"total_treat_days":8,"duration_days":10,"treatment_day1":"Fluid","treatment_day10":"ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":11,"in_time":"2012-10-12 16:30:00","ou_time":"2012-11-15 06:48:22","inno":117,"death_dt":"2012-11-15 05:33:00","total_treat_days":32,"duration_days":34,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B","treatment_day30":"ANTI_B VASO ANTI_B Sedative ANTI_B Fluid ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":12,"in_time":"2017-10-13 16:24:00","ou_time":"2017-10-30 18:35:00","inno":9003,"death_dt":null,"total_treat_days":18,"duration_days":18,"treatment_day1":"ANTI_B","treatment_day10":"Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":13,"in_time":"2017-03-12 15:03:00","ou_time":"2017-03-22 10:42:00","inno":195,"death_dt":"2017-03-22 10:42:00","total_treat_days":10,"duration_days":10,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B VASO Analgesics Fluid ANTI_B NaHCO3 Fluid VASO NaHCO3 Fluid VASO Steroid Fluid NM_Blocker NaHCO3 ANTI_B NaHCO3 Fluid NaHCO3 Steroid NaHCO3 Fluid VASO ANTI_B NM_Blocker","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"X","30day":null,"60day":null},
{"id":14,"in_time":"2014-05-08 17:49:00","ou_time":"2014-06-07 02:08:11","inno":199,"death_dt":"2014-06-07 00:50:00","total_treat_days":31,"duration_days":30,"treatment_day1":"Trans","treatment_day10":"ANTI_B Trans ANTI_B Trans ANTI_B","treatment_day30":"VASO ANTI_F ANTI_B Fluid ANTI_B Albumin Analgesics ANTI_B Fluid ANTI_F Fluid Trans ANTI_B NM_Blocker VASO","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"X","60day":null},
{"id":15,"in_time":"2011-06-22 15:02:00","ou_time":"2011-07-17 12:01:00","inno":211,"death_dt":"2011-07-17 10:50:00","total_treat_days":26,"duration_days":25,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Fluid Trans","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":16,"in_time":"2010-08-03 15:40:00","ou_time":"2010-08-23 22:30:00","inno":243,"death_dt":"2010-08-23 22:17:00","total_treat_days":14,"duration_days":21,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B Albumin Analgesics Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":17,"in_time":"2010-06-02 15:15:00","ou_time":"2010-07-26 13:27:07","inno":246,"death_dt":null,"total_treat_days":54,"duration_days":54,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B","treatment_day30":"Fluid ANTI_B NM_Blocker Sedative Analgesics Albumin Fluid Albumin Fluid ANTI_B Fluid ANTI_B Fluid","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":18,"in_time":"2018-01-08 14:07:00","ou_time":"2018-01-30 09:45:29","inno":260,"death_dt":"2018-01-30 06:25:00","total_treat_days":17,"duration_days":22,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":19,"in_time":"2011-04-07 17:03:00","ou_time":"2011-04-14 21:13:50","inno":287,"death_dt":"2011-04-14 19:15:00","total_treat_days":8,"duration_days":8,"treatment_day1":"Fluid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":20,"in_time":"2010-06-29 15:40:00","ou_time":"2010-07-17 21:09:44","inno":292,"death_dt":"2010-07-17 20:25:00","total_treat_days":19,"duration_days":19,"treatment_day1":"NaHCO3","treatment_day10":"ANTI_B Fluid ANTI_B Trans ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":21,"in_time":"2019-12-31 14:14:00","ou_time":"2020-01-22 17:54:59","inno":301,"death_dt":"2020-01-22 17:37:00","total_treat_days":23,"duration_days":23,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":22,"in_time":"2011-06-04 14:59:00","ou_time":"2011-06-11 10:18:37","inno":321,"death_dt":"2011-06-11 08:50:00","total_treat_days":5,"duration_days":7,"treatment_day1":"No treatment found on the specified date","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":23,"in_time":"2018-05-26 14:58:00","ou_time":"2018-07-16 04:59:37","inno":323,"death_dt":"2018-07-16 04:00:00","total_treat_days":44,"duration_days":51,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B Trans Fluid Sedative ANTI_B","treatment_day30":"Fluid","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":24,"in_time":"2013-07-19 15:59:00","ou_time":"2013-08-28 05:40:44","inno":336,"death_dt":"2013-08-28 04:23:00","total_treat_days":41,"duration_days":40,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Fluid Albumin ANTI_B","treatment_day30":"ANTI_B Sedative ANTI_B Fluid Sedative Albumin ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":25,"in_time":"2010-12-10 17:11:00","ou_time":"2010-12-27 23:45:00","inno":392,"death_dt":"2010-12-27 23:45:00","total_treat_days":11,"duration_days":18,"treatment_day1":"No treatment found on the specified date","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":26,"in_time":"2017-08-25 16:38:00","ou_time":"2017-08-31 11:45:56","inno":22369,"death_dt":"2017-08-31 10:40:00","total_treat_days":7,"duration_days":6,"treatment_day1":"Fluid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":27,"in_time":"2016-05-30 14:23:00","ou_time":"2016-06-27 18:46:10","inno":408,"death_dt":"2016-06-27 18:05:00","total_treat_days":26,"duration_days":29,"treatment_day1":"Fluid","treatment_day10":"Fluid ANTI_B Analgesics Trans ANTI_B Analgesics Fluid Trans Fluid Trans Albumin Trans Analgesics Albumin ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":28,"in_time":"2019-08-28 15:42:00","ou_time":"2019-09-10 06:05:31","inno":23638,"death_dt":"2019-09-10 05:51:00","total_treat_days":14,"duration_days":13,"treatment_day1":"ANTI_B Trans","treatment_day10":"ANTI_B Steroid ANTI_B Albumin ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":29,"in_time":"2014-05-15 15:08:00","ou_time":"2014-06-30 23:10:24","inno":412,"death_dt":"2014-06-30 23:10:00","total_treat_days":45,"duration_days":47,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B Fluid Albumin ANTI_B Fluid ANTI_B","treatment_day30":"ANTI_B NaHCO3 ANTI_B Fluid VASO ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":30,"in_time":"2018-06-05 16:57:00","ou_time":"2018-06-12 16:15:15","inno":420,"death_dt":"2018-06-12 14:20:00","total_treat_days":7,"duration_days":7,"treatment_day1":"No treatment found on the specified date","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":31,"in_time":"2011-03-25 16:25:00","ou_time":"2011-04-23 23:08:50","inno":438,"death_dt":"2011-04-23 21:43:00","total_treat_days":28,"duration_days":30,"treatment_day1":"Fluid","treatment_day10":"Trans Fluid Analgesics ANTI_B Albumin ANTI_B","treatment_day30":"Trans ANTI_B Albumin Analgesics Sedative Fluid VASO ANTI_B VASO Fluid NaHCO3 ANTI_B Trans ANTI_B Steroid Albumin Steroid ANTI_B VASO Trans NaHCO3 Trans NaHCO3 NM_Blocker Steroid ANTI_B Steroid NaHCO3 Steroid NaHCO3 Fluid ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"X","60day":null},
{"id":32,"in_time":"2010-02-24 16:33:00","ou_time":"2010-03-21 16:05:00","inno":478,"death_dt":"2010-03-21 16:05:00","total_treat_days":26,"duration_days":25,"treatment_day1":"Fluid ANTI_B","treatment_day10":"Fluid Steroid ANTI_F Steroid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":33,"in_time":"2012-02-13 15:01:00","ou_time":"2012-03-14 10:04:17","inno":500,"death_dt":"2012-03-14 07:41:00","total_treat_days":31,"duration_days":30,"treatment_day1":"Sedative","treatment_day10":"ANTI_B","treatment_day30":"ANTI_B Fluid Sedative Fluid Analgesics Fluid NM_Blocker ANTI_B Fluid ANTI_B VASO Albumin ANTI_B Fluid ANTI_B NM_Blocker","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"X","60day":null},
{"id":34,"in_time":"2015-02-09 16:35:00","ou_time":"2015-02-14 01:45:30","inno":30219,"death_dt":"2015-02-14 00:42:00","total_treat_days":5,"duration_days":5,"treatment_day1":"Fluid Analgesics Trans Fluid VASO Trans NaHCO3 NM_Blocker Sedative VASO ANTI_B NaHCO3 Analgesics Sedative Fluid NaHCO3","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":35,"in_time":"2015-04-12 15:26:00","ou_time":"2015-04-24 22:12:18","inno":522,"death_dt":"2015-04-24 21:17:00","total_treat_days":11,"duration_days":13,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Sedative ANTI_B Albumin ANTI_B Sedative Fluid ANTI_B Analgesics Albumin ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":36,"in_time":"2013-11-20 16:42:00","ou_time":"2013-12-02 14:06:31","inno":523,"death_dt":"2013-12-02 02:18:00","total_treat_days":12,"duration_days":12,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Albumin ANTI_B Trans ANTI_B Sedative Steroid Trans NM_Blocker Analgesics ANTI_B Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":37,"in_time":"2014-12-06 15:53:00","ou_time":"2015-02-10 16:50:07","inno":525,"death_dt":"2015-02-10 15:04:00","total_treat_days":67,"duration_days":67,"treatment_day1":"Fluid Analgesics ANTI_B","treatment_day10":"NM_Blocker ANTI_B Sedative ANTI_B Sedative ANTI_B Sedative VASO ANTI_B Analgesics","treatment_day30":"ANTI_B Fluid ANTI_B","treatment_day60":"Fluid VASO ANTI_B Sedative ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":38,"in_time":"2009-04-13 15:59:00","ou_time":"2009-05-23 23:50:37","inno":529,"death_dt":"2009-05-23 19:20:00","total_treat_days":40,"duration_days":41,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Analgesics ANTI_B VASO Sedative ANTI_B","treatment_day30":"VASO ANTI_B Albumin ANTI_B Trans ANTI_B Albumin Trans ANTI_B VASO Fluid ANTI_B VASO Analgesics Sedative ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":39,"in_time":"2013-10-04 16:22:00","ou_time":"2013-10-19 02:13:00","inno":530,"death_dt":"2013-10-19 01:50:00","total_treat_days":13,"duration_days":15,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":40,"in_time":"2015-11-24 17:11:00","ou_time":"2016-02-11 13:37:37","inno":531,"death_dt":null,"total_treat_days":74,"duration_days":79,"treatment_day1":"Fluid","treatment_day10":"ANTI_B","treatment_day30":"ANTI_B Trans ANTI_B","treatment_day60":"ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":41,"in_time":"2009-02-07 16:30:00","ou_time":"2009-03-05 20:08:56","inno":544,"death_dt":"2009-03-05 19:00:00","total_treat_days":23,"duration_days":27,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_F ANTI_B NaHCO3 Steroid Trans ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":42,"in_time":"2011-09-19 16:01:00","ou_time":"2011-10-24 07:52:31","inno":570,"death_dt":"2011-10-24 05:18:00","total_treat_days":28,"duration_days":35,"treatment_day1":"Trans","treatment_day10":"Fluid ANTI_B Analgesics Trans ANTI_B","treatment_day30":"ANTI_B Albumin ANTI_B IVIG ANTI_B Albumin IVIG Albumin ANTI_B IVIG","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":43,"in_time":"2011-05-17 16:40:00","ou_time":"2011-05-29 14:40:00","inno":577,"death_dt":"2011-05-29 05:52:00","total_treat_days":13,"duration_days":12,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Trans Albumin ANTI_B Trans Albumin VASO ANTI_B Fluid","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":44,"in_time":"2018-03-10 14:59:00","ou_time":"2018-03-14 22:12:47","inno":33414,"death_dt":"2018-03-14 21:32:00","total_treat_days":5,"duration_days":5,"treatment_day1":"Steroid Fluid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":45,"in_time":"2016-12-06 15:29:00","ou_time":"2017-01-18 02:23:33","inno":584,"death_dt":"2017-01-18 01:29:00","total_treat_days":42,"duration_days":43,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B Fluid Analgesics Fluid ANTI_B Albumin","treatment_day30":"Sedative VASO ANTI_B Trans ANTI_B Sedative Albumin ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":46,"in_time":"2017-03-24 13:41:00","ou_time":"2017-05-09 05:30:53","inno":601,"death_dt":"2017-05-09 03:23:00","total_treat_days":46,"duration_days":46,"treatment_day1":"Fluid ANTI_B","treatment_day10":"Fluid Analgesics ANTI_B","treatment_day30":"Fluid ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":47,"in_time":"2010-11-08 17:00:00","ou_time":"2010-11-21 23:16:38","inno":613,"death_dt":"2010-11-21 14:50:00","total_treat_days":13,"duration_days":14,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":48,"in_time":"2009-06-12 17:49:00","ou_time":"2009-06-21 23:29:36","inno":663,"death_dt":"2009-06-21 23:04:00","total_treat_days":9,"duration_days":10,"treatment_day1":"No treatment found on the specified date","treatment_day10":"NaHCO3 Fluid NaHCO3 ANTI_B Analgesics Sedative VASO NaHCO3","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"X","30day":null,"60day":null},
{"id":49,"in_time":"2017-06-25 16:01:00","ou_time":"2017-09-26 04:16:21","inno":711,"death_dt":"2017-09-26 03:48:00","total_treat_days":90,"duration_days":93,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid ANTI_B","treatment_day30":"ANTI_B VASO ANTI_B NM_Blocker Fluid VASO ANTI_B Fluid Analgesics ANTI_B","treatment_day60":"ANTI_B Fluid ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":50,"in_time":"2018-12-24 14:46:00","ou_time":"2019-01-21 03:05:51","inno":745,"death_dt":"2019-01-21 02:27:00","total_treat_days":28,"duration_days":28,"treatment_day1":"Fluid ANTI_B","treatment_day10":"ANTI_B ANTI_T ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":51,"in_time":"2010-07-01 15:06:00","ou_time":"2010-07-27 23:24:03","inno":800,"death_dt":"2010-07-27 21:40:00","total_treat_days":27,"duration_days":27,"treatment_day1":"Fluid","treatment_day10":"ANTI_B NaHCO3 ANTI_B NaHCO3 ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":52,"in_time":"2019-09-30 18:21:00","ou_time":"2019-10-18 11:15:48","inno":808,"death_dt":"2019-10-18 10:46:00","total_treat_days":19,"duration_days":18,"treatment_day1":"Sedative Analgesics ANTI_B","treatment_day10":"Sedative Analgesics ANTI_B Steroid Albumin ANTI_B Sedative ANTI_V ANTI_B VASO Fluid Sedative Steroid ANTI_B Analgesics ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":53,"in_time":"2009-12-21 16:47:00","ou_time":"2010-03-18 04:28:34","inno":809,"death_dt":"2010-03-18 02:53:00","total_treat_days":75,"duration_days":87,"treatment_day1":"Fluid Trans","treatment_day10":"Albumin Analgesics Albumin ANTI_B Albumin Analgesics Fluid Analgesics ANTI_B","treatment_day30":"Albumin ANTI_B","treatment_day60":"ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":54,"in_time":"2020-09-07 15:37:00","ou_time":"2020-10-04 12:29:19","inno":810,"death_dt":"2020-10-04 12:03:00","total_treat_days":28,"duration_days":27,"treatment_day1":"ANTI_B ANTI_F","treatment_day10":"ANTI_V ANTI_B ANTI_F NaHCO3 ANTI_V","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":55,"in_time":"2010-11-09 16:34:00","ou_time":"2010-12-06 11:39:23","inno":868,"death_dt":"2010-12-06 08:24:00","total_treat_days":27,"duration_days":27,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B ANTI_T ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":56,"in_time":"2016-04-03 16:57:00","ou_time":"2016-05-21 21:46:26","inno":888,"death_dt":"2016-05-21 19:20:00","total_treat_days":48,"duration_days":49,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Analgesics Fluid ANTI_B Albumin Sedative Trans ANTI_B Albumin Analgesics ANTI_B","treatment_day30":"NM_Blocker ANTI_B Fluid Trans ANTI_B Trans Albumin Analgesics ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":57,"in_time":"2011-02-01 14:59:00","ou_time":"2011-03-07 16:41:40","inno":11645,"death_dt":"2011-03-07 15:14:00","total_treat_days":35,"duration_days":35,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B ANTI_F","treatment_day30":"ANTI_F","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":58,"in_time":"2020-10-31 14:48:00","ou_time":"2020-11-05 15:16:59","inno":931,"death_dt":"2020-11-05 14:02:00","total_treat_days":6,"duration_days":6,"treatment_day1":"ANTI_B Fluid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":59,"in_time":"2011-06-23 16:45:00","ou_time":"2011-07-14 17:29:31","inno":938,"death_dt":"2011-07-14 16:37:00","total_treat_days":22,"duration_days":22,"treatment_day1":"ANTI_B Fluid","treatment_day10":"NM_Blocker Sedative VASO Fluid VASO ANTI_B Albumin NM_Blocker Fluid Analgesics ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":60,"in_time":"2012-04-10 15:58:00","ou_time":"2012-07-02 07:34:27","inno":945,"death_dt":"2012-07-02 06:45:00","total_treat_days":82,"duration_days":83,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Steroid","treatment_day30":"ANTI_B Steroid ANTI_B","treatment_day60":"ANTI_B Steroid ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":61,"in_time":"2020-01-04 13:55:00","ou_time":"2020-02-27 14:20:53","inno":14387,"death_dt":"2020-02-27 12:21:00","total_treat_days":53,"duration_days":55,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Sedative ANTI_B Sedative Steroid ANTI_B Albumin Fluid ANTI_B Sedative Steroid VASO Fluid NM_Blocker Steroid Sedative ANTI_B Sedative","treatment_day30":"ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":62,"in_time":"2013-09-04 16:56:00","ou_time":"2013-10-08 19:31:30","inno":14389,"death_dt":"2013-10-08 18:20:00","total_treat_days":35,"duration_days":35,"treatment_day1":"Fluid Trans","treatment_day10":"Fluid ANTI_F Steroid Trans Fluid Albumin Steroid Trans ANTI_F","treatment_day30":"ANTI_B Steroid ANTI_V ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":63,"in_time":"2013-11-16 14:58:00","ou_time":"2014-01-15 16:36:00","inno":977,"death_dt":"2014-01-15 15:05:00","total_treat_days":60,"duration_days":61,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid ANTI_B Albumin IVIG ANTI_B Fluid Analgesics Sedative ANTI_B","treatment_day30":"ANTI_B Analgesics ANTI_B Fluid ANTI_B Fluid VASO ANTI_B","treatment_day60":"NaHCO3 Fluid Sedative ANTI_B Analgesics ANTI_B Fluid VASO Fluid ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":64,"in_time":"2017-07-07 15:01:00","ou_time":"2017-08-03 08:39:42","inno":16076,"death_dt":"2017-08-03 07:07:00","total_treat_days":25,"duration_days":27,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Analgesics","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":65,"in_time":"2013-07-26 15:01:00","ou_time":"2013-08-08 10:36:29","inno":998,"death_dt":"2013-08-08 04:04:00","total_treat_days":14,"duration_days":13,"treatment_day1":"Fluid","treatment_day10":"ANTI_B Steroid ANTI_B Sedative NM_Blocker ANTI_B Trans ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":66,"in_time":"2015-10-14 15:26:00","ou_time":"2015-12-16 22:00:36","inno":1044,"death_dt":"2015-12-16 21:57:00","total_treat_days":64,"duration_days":64,"treatment_day1":"Trans","treatment_day10":"ANTI_B NaHCO3 Trans Fluid ANTI_B NaHCO3","treatment_day30":"ANTI_B ANTI_F ANTI_B ANTI_F ANTI_B ANTI_F Trans ANTI_B","treatment_day60":"ANTI_B Sedative NM_Blocker Fluid ANTI_B Fluid ANTI_F Trans ANTI_B Steroid NM_Blocker ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":67,"in_time":"2012-09-17 17:18:00","ou_time":"2012-10-07 15:26:00","inno":20549,"death_dt":"2012-10-07 14:10:00","total_treat_days":19,"duration_days":20,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Trans NaHCO3","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":68,"in_time":"2016-12-05 15:28:00","ou_time":"2016-12-24 09:22:34","inno":21660,"death_dt":"2016-12-24 07:12:00","total_treat_days":20,"duration_days":19,"treatment_day1":"Fluid ANTI_B","treatment_day10":"ANTI_B NaHCO3 ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":69,"in_time":"2016-01-09 15:57:00","ou_time":"2016-01-22 19:37:12","inno":1106,"death_dt":"2016-01-22 18:45:00","total_treat_days":11,"duration_days":14,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid Analgesics ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":70,"in_time":"2009-02-13 16:25:00","ou_time":"2009-02-22 21:26:00","inno":1135,"death_dt":"2009-02-22 21:26:00","total_treat_days":7,"duration_days":10,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"X","30day":null,"60day":null},
{"id":71,"in_time":"2015-06-25 14:38:00","ou_time":"2015-07-05 03:04:47","inno":23734,"death_dt":"2015-07-05 02:26:00","total_treat_days":9,"duration_days":10,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Sedative Trans Sedative ANTI_B Fluid Trans Sedative VASO NM_Blocker Fluid Analgesics Steroid VASO Fluid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"X","30day":null,"60day":null},
{"id":72,"in_time":"2015-08-06 16:32:00","ou_time":"2015-09-01 14:32:01","inno":1163,"death_dt":"2015-09-01 11:28:00","total_treat_days":27,"duration_days":26,"treatment_day1":"Fluid","treatment_day10":"Steroid ANTI_B Fluid ANTI_B Steroid","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":73,"in_time":"2013-12-15 15:36:00","ou_time":"2014-01-26 02:36:44","inno":1189,"death_dt":"2014-01-26 01:45:00","total_treat_days":37,"duration_days":42,"treatment_day1":"Trans","treatment_day10":"NaHCO3 Steroid Fluid NaHCO3 Steroid","treatment_day30":"ANTI_B Fluid ANTI_F ANTI_B Trans ANTI_F ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":74,"in_time":"2009-08-14 15:05:00","ou_time":"2009-10-08 23:22:12","inno":1205,"death_dt":"2009-10-08 22:30:00","total_treat_days":53,"duration_days":56,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Steroid","treatment_day30":"ANTI_F Steroid ANTI_F","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":75,"in_time":"2010-04-30 15:56:00","ou_time":"2010-07-16 05:17:13","inno":1216,"death_dt":"2010-07-16 04:00:00","total_treat_days":76,"duration_days":77,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B Sedative ANTI_B","treatment_day30":"Fluid ANTI_B","treatment_day60":"Trans ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":76,"in_time":"2014-07-02 16:26:00","ou_time":"2014-08-20 17:12:21","inno":28016,"death_dt":"2014-08-20 15:20:00","total_treat_days":49,"duration_days":50,"treatment_day1":"Fluid","treatment_day10":"Trans ANTI_B Albumin Sedative ANTI_B IVIG Sedative ANTI_B","treatment_day30":"ANTI_B VASO Fluid ANTI_B Fluid","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":77,"in_time":"2015-02-28 15:38:00","ou_time":"2015-05-12 18:16:00","inno":1239,"death_dt":"2015-05-12 18:16:00","total_treat_days":59,"duration_days":74,"treatment_day1":"Fluid ANTI_B","treatment_day10":"Fluid ANTI_B Fluid","treatment_day30":"No treatment found on the specified date","treatment_day60":"Albumin Analgesics Sedative Albumin ANTI_B Steroid Analgesics Sedative Albumin ANTI_B Albumin ANTI_B Steroid Analgesics ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":78,"in_time":"2010-06-03 16:23:00","ou_time":"2010-07-31 17:42:00","inno":1245,"death_dt":"2010-07-31 17:05:00","total_treat_days":58,"duration_days":59,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Steroid","treatment_day30":"Steroid","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":79,"in_time":"2010-06-22 16:35:00","ou_time":"2010-06-28 09:24:00","inno":29063,"death_dt":"2010-06-28 10:03:00","total_treat_days":7,"duration_days":6,"treatment_day1":"ANTI_B","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":80,"in_time":"2011-01-17 16:51:00","ou_time":"2011-03-19 13:46:01","inno":33344,"death_dt":"2011-03-19 11:30:00","total_treat_days":62,"duration_days":61,"treatment_day1":"ANTI_B Steroid","treatment_day10":"Steroid ANTI_B Sedative Fluid Analgesics Steroid ANTI_B","treatment_day30":"ANTI_B Steroid ANTI_B ANTI_F ANTI_B NM_Blocker Sedative Analgesics Sedative Steroid ANTI_B","treatment_day60":"ANTI_B Steroid ANTI_F ANTI_B Fluid ANTI_B Steroid Fluid ANTI_B VASO ANTI_B NM_Blocker","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":81,"in_time":"2012-01-20 15:20:00","ou_time":"2012-02-11 06:09:39","inno":1354,"death_dt":"2012-02-11 04:25:00","total_treat_days":20,"duration_days":22,"treatment_day1":"ANTI_B","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":82,"in_time":"2010-11-20 15:43:00","ou_time":"2011-01-01 16:27:41","inno":1363,"death_dt":"2011-01-01 15:45:00","total_treat_days":33,"duration_days":43,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_F Fluid ANTI_F","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":83,"in_time":"2011-11-18 15:51:00","ou_time":"2011-11-25 13:35:16","inno":35267,"death_dt":null,"total_treat_days":8,"duration_days":7,"treatment_day1":"Fluid","treatment_day10":"No treatment found on the specified date","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":null,"30day":null,"60day":null},
{"id":84,"in_time":"2012-12-26 14:48:00","ou_time":"2013-01-28 16:40:08","inno":1370,"death_dt":"2013-01-28 16:40:00","total_treat_days":34,"duration_days":34,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B","treatment_day30":"Trans Fluid Analgesics Sedative Trans ANTI_B Fluid ANTI_B Fluid VASO Fluid Trans Fluid Trans Albumin Trans Fluid Albumin Fluid Trans Fluid ANTI_B Trans Fluid Trans Fluid Trans Sedative Analgesics Trans Fluid ANTI_B Trans","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":85,"in_time":"2010-11-04 15:04:00","ou_time":"2011-01-17 15:25:30","inno":1420,"death_dt":"2011-01-17 13:45:00","total_treat_days":69,"duration_days":75,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_F","treatment_day30":"Sedative NM_Blocker Analgesics ANTI_B","treatment_day60":"Analgesics ANTI_B Analgesics ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":86,"in_time":"2016-10-06 13:36:00","ou_time":"2016-11-17 23:52:00","inno":39085,"death_dt":"2016-11-17 23:52:00","total_treat_days":42,"duration_days":43,"treatment_day1":"ANTI_B","treatment_day10":"Albumin","treatment_day30":"ANTI_B Trans ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":87,"in_time":"2015-02-16 16:47:00","ou_time":"2015-03-22 05:24:41","inno":1480,"death_dt":"2015-03-22 04:15:00","total_treat_days":31,"duration_days":34,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Analgesics Steroid Analgesics ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":88,"in_time":"2017-08-29 15:50:00","ou_time":"2017-10-25 12:22:55","inno":40069,"death_dt":null,"total_treat_days":58,"duration_days":57,"treatment_day1":"ANTI_B","treatment_day10":"Fluid Steroid ANTI_B","treatment_day30":"Trans Steroid ANTI_B Sedative ANTI_B Steroid Fluid ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":89,"in_time":"2009-06-20 13:55:00","ou_time":"2009-07-26 04:00:32","inno":1505,"death_dt":"2009-07-26 02:40:00","total_treat_days":36,"duration_days":36,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B VASO Fluid ANTI_B Fluid","treatment_day30":"Analgesics Sedative VASO ANTI_B Analgesics Fluid Sedative Analgesics VASO Fluid ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":90,"in_time":"2009-02-09 15:25:00","ou_time":"2009-08-07 16:25:31","inno":1578,"death_dt":null,"total_treat_days":172,"duration_days":180,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Albumin","treatment_day30":"Steroid ANTI_B ANTI_V Albumin Analgesics Steroid Fluid Trans","treatment_day60":"ANTI_B Albumin Steroid ANTI_B Albumin Steroid Analgesics ANTI_B Albumin Trans Steroid ANTI_B Trans","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":91,"in_time":"2009-03-20 16:06:00","ou_time":"2009-04-06 16:43:00","inno":1606,"death_dt":"2009-04-06 12:42:00","total_treat_days":16,"duration_days":18,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Steroid VASO NaHCO3 ANTI_B IVIG ANTI_B Albumin Steroid Fluid Albumin Trans NaHCO3 Steroid Sedative Fluid Analgesics VASO Trans ANTI_B Steroid","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":92,"in_time":"2009-06-19 15:43:00","ou_time":"2009-08-15 05:41:43","inno":1661,"death_dt":"2009-08-15 04:10:00","total_treat_days":58,"duration_days":57,"treatment_day1":"Fluid","treatment_day10":"VASO Fluid ANTI_B Fluid ANTI_B Fluid","treatment_day30":"Analgesics Albumin ANTI_B Analgesics VASO Analgesics","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":93,"in_time":"2014-09-13 14:55:00","ou_time":"2014-10-11 03:53:45","inno":1663,"death_dt":"2014-10-11 03:20:00","total_treat_days":26,"duration_days":28,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid Analgesics ANTI_B Steroid ANTI_B Fluid Sedative ANTI_B NM_Blocker ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":94,"in_time":"2017-02-11 15:52:00","ou_time":"2017-03-25 23:11:31","inno":1690,"death_dt":"2017-03-25 23:11:00","total_treat_days":41,"duration_days":43,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Fluid Sedative Albumin Fluid ANTI_B","treatment_day30":"Fluid Sedative ANTI_B Fluid Analgesics ANTI_F ANTI_B Albumin Sedative ANTI_B Fluid Analgesics ANTI_B","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":"O","60day":null},
{"id":95,"in_time":"2010-09-03 15:21:00","ou_time":"2010-09-16 22:45:00","inno":10266,"death_dt":"2010-09-16 22:45:00","total_treat_days":14,"duration_days":14,"treatment_day1":"Steroid","treatment_day10":"Steroid","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":96,"in_time":"2012-04-13 15:50:00","ou_time":"2013-12-29 04:54:22","inno":1711,"death_dt":"2013-12-29 03:14:00","total_treat_days":622,"duration_days":625,"treatment_day1":"ANTI_B","treatment_day10":"ANTI_B Steroid ANTI_B","treatment_day30":"ANTI_B Steroid ANTI_B Fluid Steroid ANTI_B","treatment_day60":"Steroid ANTI_B Steroid","1day":"O","10day":"O","30day":"O","60day":"O"},
{"id":97,"in_time":"2009-09-30 16:10:00","ou_time":"2009-10-20 23:50:14","inno":1714,"death_dt":"2009-10-20 22:54:00","total_treat_days":20,"duration_days":21,"treatment_day1":"No treatment found on the specified date","treatment_day10":"ANTI_B Steroid VASO Fluid ANTI_B Albumin ANTI_B Steroid ANTI_B Steroid ANTI_B","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":98,"in_time":"2015-07-24 14:59:00","ou_time":"2015-08-10 14:17:10","inno":11013,"death_dt":"2015-08-10 12:57:00","total_treat_days":11,"duration_days":17,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Fluid ANTI_B ANTI_F","treatment_day30":"No treatment found on the specified date","treatment_day60":"No treatment found on the specified date","1day":"O","10day":"O","30day":null,"60day":null},
{"id":99,"in_time":"2013-08-01 14:50:00","ou_time":"2013-10-03 12:25:19","inno":11265,"death_dt":"2013-10-03 10:20:00","total_treat_days":60,"duration_days":63,"treatment_day1":"No treatment found on the specified date","treatment_day10":"Sedative ANTI_B VASO Fluid Analgesics ANTI_B","treatment_day30":"ANTI_B VASO Trans Sedative NM_Blocker ANTI_B","treatment_day60":"Trans ANTI_B Fluid VASO Sedative NM_Blocker Sedative Analgesics ANTI_B Sedative ANTI_B","1day":"O","10day":"O","30day":"O","60day":"O"}
];

const DataGridExample = () => {
  return (
    <Card>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Grid item xs={12} md={6}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [{ field: 'in_time', sort: 'asc' }],
                },
              }}
              pageSizeOptions={[5]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
        </Grid>
      </Box>
    </Card>
  );
}


export default DataGridExample;