import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  Resporations: string,
  SystolicBP: string,
  HeartRate: string,
  Temperature: string,
  Consciousness : string,
  SpO2 : string,
  O2Supplement : string
) {
  return { Resporations, SystolicBP, HeartRate, Temperature, Consciousness, SpO2, O2Supplement };
}

const rows = [
  createData('22', '101', '101', '37.5', 'A', '96', 'Y')
];
const tableStyle = {
  // backgroundColor: '#f5f5f5', // 연한 회색 배경색
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
};

const cellStyle = {
  border: '1px solid rgba(225, 224, 224, 1)', // 테두리 스타일 설정
  fontSize: '14px'
};

const paperStyle = {
  boxShadow: 'none', // 그림자 효과 제거
};

export default function News() {
  return (
    <TableContainer component={Paper} sx={{ ...tableStyle, ...paperStyle }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: '#d2d2d2', color: '#181d27' }}>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Resporations</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Systolic BP</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Heart Rate</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Temperature</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>Consciousness</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>SpO2</TableCell>
            <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>O2 supplement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.gcs}
              sx={cellStyle}
              align="center"
            >
                {/* Resporations, SystolicBP, HeartRate, Temperature, Consciousness, SpO2, O2Supplement */}
              <TableCell align="center" component="th" scope="row" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.Resporations}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.SystolicBP}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.HeartRate}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.Temperature}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.Consciousness}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.SpO2}</TableCell>
              <TableCell align="center" sx={{...cellStyle, whiteSpace: 'nowrap'}}>{row.O2Supplement}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
