import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

interface Bundle {
  name: string;
  selfcheck: string; // 추가
  '1h': string;      // 추가
  '3h': string;      // 추가
  '6h': string;      // 추가
}

interface BundleTableProps {
  bundleItems: Bundle[];
  onUpdateBundle: (bundleName: string, field: string, value: string) => void;
}

export default function BundleTable({ bundleItems, onUpdateBundle }: BundleTableProps) {
  const handleRadioChange = (bundleName: string, field: string, value: string) => {
    onUpdateBundle(bundleName, field, value);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Bundles</TableCell>
            <TableCell align="center">Self Check</TableCell>
            <TableCell align="center">1h</TableCell>
            <TableCell align="center">3h</TableCell>
            <TableCell align="center">6h</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bundleItems.map((bundle) => (
            <TableRow key={bundle.name}>
              <TableCell align="center">{bundle.name}</TableCell>
              {/* Self Check */}
              <TableCell align="center">
                <FormControl>
                  <RadioGroup
                    row
                    name={`position_${bundle.name}_selfcheck`}
                    value={bundle.selfcheck}
                    defaultValue="없음"
                    onChange={(e) => handleRadioChange(bundle.name, 'selfcheck', e.target.value)}
                  >
                    <FormControlLabel
                      value="없음"
                      control={<Radio />}
                      label="없음"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="처방"
                      control={<Radio />}
                      label="처방"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="결과"
                      control={<Radio />}
                      label="결과"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
              {/* 1h */}
              <TableCell align="center">
                <FormControl>
                  <RadioGroup
                    row
                    name={`position_${bundle.name}_1h`}
                    value={bundle['1h']}
                    defaultValue="없음"
                    onChange={(e) => handleRadioChange(bundle.name, '1h', e.target.value)}
                  >
                    <FormControlLabel
                      value="없음"
                      control={<Radio />}
                      label="없음"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="처방"
                      control={<Radio />}
                      label="처방"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="결과"
                      control={<Radio />}
                      label="결과"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
              {/* 3h */}
              <TableCell align="center">
                <FormControl>
                  <RadioGroup
                    row
                    name={`position_${bundle.name}_3h`}
                    value={bundle['3h']}
                    defaultValue="없음"
                    onChange={(e) => handleRadioChange(bundle.name, '3h', e.target.value)}
                  >
                    <FormControlLabel
                      value="없음"
                      control={<Radio />}
                      label="없음"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="처방"
                      control={<Radio />}
                      label="처방"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="결과"
                      control={<Radio />}
                      label="결과"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
              {/* 6h */}
              <TableCell align="center">
                <FormControl>
                  <RadioGroup
                    row
                    name={`position_${bundle.name}_6h`}
                    defaultValue="없음"
                    value={bundle['6h']}
                    onChange={(e) => handleRadioChange(bundle.name, '6h', e.target.value)}
                  >
                    <FormControlLabel
                      value="없음"
                      control={<Radio />}
                      label="없음"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="처방"
                      control={<Radio />}
                      label="처방"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="결과"
                      control={<Radio />}
                      label="결과"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
