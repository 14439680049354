import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CircularProgress } from '@mui/material';
import MDBox from "components/MDBox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: 'center',
    color: 'white'
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
    fontSize: 14,
    // pr: '1%',  // 우측 여백 설정
  },
  // Additional styling for the arrow icon
  '.arrow-icon': {
    fontSize: '18px',
    marginLeft: '4px',
  },
  '.increase': {
    color: 'red',
  },
  '.decrease': {
    color: 'blue',
  },
  '.status-prediction-cell': {
    width: '100px',
    paddingRight: '2%',
    textAlign: 'left',
  },
}));

const StyledTableContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  borderBottomLeftRadius: '10',
  borderBottomRightRadius: '10',
  // maxHeight,
  overflow: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  no: number,
  examName: string,
  examRslt: number,
  examPredict: number,
) {
  return { no, examName, examRslt, examPredict };
}

const rows1 = [
  createData(1, 'SBP', '148', '153'),
  createData(1, 'HR', '100', '73'),
  createData(1, 'BT', '36.6', '36.3'),
  createData(1, 'SpO2', '87', '87'),
  createData(1, 'LacticAcid', '1.822', '1.822'),
  createData(1, 'RR', '18', '18'),
  createData(2, 'WBC', '6.2', '20.5'),
  createData(2, 'Plt', '265', '327'),
  createData(2, 'Cr', '1.3', '1.3'),
  createData(2, 'T.bil', '0.9', '0.9'),
  createData(3, 'ANC', '2678.4', '18778'),
  createData(3, 'CRP', '1.23', '1.23'),
  createData(3, 'K', '4.1', '4.1'),
  createData(3, 'HcO3', '26.4', '26.4'),
  createData(3, 'pH', '7.4', '7.4'),
  createData(4, 'DBP', '100', '98'),
  createData(4, 'Hb', 13.6, 16),
  createData(4, 'PTT', '26', '26'),
  createData(4, 'D-Dimer', '0.431', '0.431'),
  createData(4, 'BUN', '19.6', '19.6'),
  createData(4, 'Albumin', '4.1', '4.1'),
  // createData(4, 'ALP', '110', ''),
  createData(4, 'AST', '32', '32'),
  createData(4, 'ALT', '18', '18'),
  createData(4, 'CL', '106', '100'),
  createData(4, 'Mg', '1.8', '1.8'),
  createData(4, 'Pt', '10.3', '10.3'),
  createData(4, 'INR', '0.98', '0.98'),
  createData(4, 'P', '2.9', '2.9'),
  createData(4, 'Amylase', '90', '90'),
];

const rows2 = [
  createData(1, 'SBP', '148', '164'),
  createData(1, 'HR', '100', '71'),
  createData(1, 'BT', '36.6', '36.3'),
  createData(1, 'SpO2', '87', '87'),
  createData(1, 'LacticAcid', '1.822', '1.822'),
  createData(1, 'RR', '18', '18'),
  createData(2, 'WBC', '6.2', '20.5'),
  createData(2, 'Plt', '265', '327'),
  createData(2, 'Cr', '1.3', '1.3'),
  createData(2, 'T.bil', '0.9', '0.9'),
  createData(3, 'ANC', '2678.4', '18778'),
  createData(3, 'CRP', '1.23', '1.23'),
  createData(3, 'K', '4.1', '4.1'),
  createData(3, 'HcO3', '26.4', '26.4'),
  createData(3, 'pH', '7.4', '7.4'),
  createData(4, 'DBP', '100', '97'),
  createData(4, 'Hb', '13.6', '16'),
  createData(4, 'PTT', '26', '26'),
  createData(4, 'D-Dimer', '0.431', '0.431'),
  createData(4, 'BUN', '19.6', '19.6'),
  createData(4, 'Albumin', '4.1', '4.1'),
  // createData(4, 'ALP', '110', ''),
  createData(4, 'AST', '32', '32'),
  createData(4, 'ALT', '18', '18'),
  createData(4, 'CL', '106', '100'),
  createData(4, 'Mg', '1.8', '1.8'),
  createData(4, 'Pt', '10.3', '10.3'),
  createData(4, 'INR', '0.98', '0.98'),
  createData(4, 'P', '2.9', '2.9'),
  createData(4, 'Amylase', '90', '90'),
];

const rows3 = [
  createData(1, 'SBP', '148', '164'),
  createData(1, 'HR', '100', '109'),
  createData(1, 'BT', '36.6', '38'),
  createData(1, 'SpO2', '87', '99.89'),
  createData(1, 'LacticAcid', '1.822', '1.822'),
  createData(1, 'RR', '18', '10'),
  createData(2, 'WBC', '6.2', '20.5'),
  createData(2, 'Plt', '265', '327'),
  createData(2, 'Cr', '1.3', '1.3'),
  createData(2, 'T.bil', '0.9', '0.9'),
  createData(3, 'ANC', '2678.4', '18778'),
  createData(3, 'CRP', '1.23', '1.23'),
  createData(3, 'K', '4.1', '4.1'),
  createData(3, 'HcO3', '26.4', '17.9'),
  createData(3, 'pH', '7.4', '7.4'),
  createData(4, 'DBP', '100', '97'),
  createData(4, 'Hb', '13.6', '16'),
  createData(4, 'PTT', '26', '26'),
  createData(4, 'D-Dimer', '0.431', '0.431'),
  createData(4, 'BUN', '19.6', '19.6'),
  createData(4, 'Albumin', '4.1', '4.1'),
  // createData(4, 'ALP', '110', ''),
  createData(4, 'AST', '32', '32'),
  createData(4, 'ALT', '18', '18'),
  createData(4, 'CL', '106', '100'),
  createData(4, 'Mg', '1.8', '1.8'),
  createData(4, 'Pt', '10.3', '10.3'),
  createData(4, 'INR', '0.98', '0.98'),
  createData(4, 'P', '2.9', '2.9'),
  createData(4, 'Amylase', '90', '90'),
];

const rows4 = [
  createData(1, 'SBP', '148', '168'),
  createData(1, 'HR', '100', '75'),
  createData(1, 'BT', '36.6', '36.7'),
  createData(1, 'SpO2', '87', '99.89'),
  createData(1, 'LacticAcid', '1.822', '1.822'),
  createData(1, 'RR', '18', '15'),
  createData(2, 'WBC', '6.2', '14.1'),
  createData(2, 'Plt', '265', '218'),
  createData(2, 'Cr', '1.3', '1.2'),
  createData(2, 'T.bil', '0.9', '0.9'),
  createData(3, 'ANC', '2678.4', '12097.8'),
  createData(3, 'CRP', '1.23', '1.23'),
  createData(3, 'K', '4.1', '3.9'),
  createData(3, 'HcO3', '26.4', '24.8'),
  createData(3, 'pH', '7.4', '7.4'),
  createData(4, 'DBP', '100', '97'),
  createData(4, 'Hb', '13.6', '12.2'),
  createData(4, 'PTT', '26', '26'),
  createData(4, 'D-Dimer', '0.431', '0.431'),
  createData(4, 'BUN', '19.6', '31.4'),
  createData(4, 'Albumin', '4.1', '4.1'),
  // createData(4, 'ALP', '110', ''),
  createData(4, 'AST', '32', '32'),
  createData(4, 'ALT', '18', '18'),
  createData(4, 'CL', '106', '100'),
  createData(4, 'Mg', '1.8', '2.8'),
  createData(4, 'Pt', '10.3', '10.3'),
  createData(4, 'INR', '0.98', '0.98'),
  createData(4, 'P', '2.9', '2.8'),
  createData(4, 'Amylase', '90', '90'),
];

const rows5 = [
  createData(1, 'SBP', '148', '154'),
  createData(1, 'HR', '100', '86'),
  createData(1, 'BT', '36.6', '36.6'),
  createData(1, 'SpO2', '87', '100'),
  createData(1, 'LacticAcid', '1.822', '1.822'),
  createData(1, 'RR', '18', '18'),
  createData(2, 'WBC', '6.2', '10.9'),
  createData(2, 'Plt', '265', '212'),
  createData(2, 'Cr', '1.3', '1'),
  createData(2, 'T.bil', '0.9', '0.7'),
  createData(3, 'ANC', '2678.4', '8458.4'),
  createData(3, 'CRP', '1.23', '2.93'),
  createData(3, 'K', '4.1', '3.9'),
  createData(3, 'HcO3', '26.4', '28.9'),
  createData(3, 'pH', '7.4', '7.4'),
  createData(4, 'DBP', '100', '99'),
  createData(4, 'Hb', '13.6', '12.4'),
  createData(4, 'PTT', '26', '26'),
  createData(4, 'D-Dimer', '0.431', '0.431'),
  createData(4, 'BUN', '19.6', '21.8'),
  createData(4, 'Albumin', '4.1', '3.9'),
  // createData(4, 'ALP', '110', ''),
  createData(4, 'AST', '32', '18'),
  createData(4, 'ALT', '18', '14'),
  createData(4, 'CL', '106', '101'),
  createData(4, 'Mg', '1.8', '2.4'),
  createData(4, 'Pt', '10.3', '10.3'),
  createData(4, 'INR', '0.98', '0.98'),
  createData(4, 'P', '2.9', '2.5'),
  createData(4, 'Amylase', '90', '90'),
];

const PatStatePrediction = ({ state, selectrow }) => {
  const [maxHeight, setMaxHeight] = useState('65vh');
  const [currentRows, setCurrentRows] = useState(rows1);
  const [predicNum, setPredicNum] = useState('T1');
  const [loading, setLoading] = React.useState(false); 

  useEffect(() => {
    const calculateMaxHeight = () => {
      const innerWidth = window.innerWidth;
      const newMaxHeight = innerWidth === 2560 ? '53.1vh' : '64vh';
      setMaxHeight(newMaxHeight);
    };

    calculateMaxHeight();

    const handleResize = () => {
      calculateMaxHeight();
    };

    window.addEventListener('resize', handleResize);
    const initialLoadDelay = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    setLoading(true);
    if (selectrow === 'T1') {
      setPredicNum('T1');
      setCurrentRows(rows1);
      setPredictPercent(82);
    } else if (selectrow === 'T2') {
      setCurrentRows(rows2);
      setPredicNum('T2');
      setPredictPercent(90);
    } else if (selectrow === 'T3') {
      setPredicNum('T3');
      setCurrentRows(rows3);
      setPredictPercent(71);
    } else if (selectrow === 'T4') {
      setPredicNum('T4');
      setCurrentRows(rows4);
      setPredictPercent(64);
    } else if (selectrow === 'T5') {
      setPredicNum('T5');
      setCurrentRows(rows5);
      setPredictPercent(80);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 300);
  
    // cleanup 함수
    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectrow])

  const [predictPercent, setPredictPercent] = useState(82);

  const renderArrowIcon = (change) => {
    if (change !== 0) {
      const arrowClass = change > 0 ? 'increase' : 'decrease';
      const ArrowIcon = change > 0 ? ArrowUpwardIcon : ArrowDownwardIcon;
      return (
        <span className={`arrow-icon ${arrowClass}`}>
          <ArrowIcon />
        </span>
      );
    } else {
      // change가 0인 경우
      return null; // 아무것도 반환하지 않음
    }
  };
  

  return (
    <>
    {
      loading?
      (
        <MDBox display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        margin="auto"
        height="100vh"
        width="50vh">
          <CircularProgress />
        </MDBox>
      )
      :
      (
        <Card sx={{ width: '100%', maxHeight, paddingRight: '0.5%', mt:3, mb:3, boxShadow: 'none' }}>
        <Box sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" sx={{ textAlign: 'center', m: 0, marginLeft: '1%', marginTop: '1%' }}>{predicNum} 환자상태 예측</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, alignItems: 'center', width: '30%', mx: 'auto' }}>
            <Box sx={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #d3d3d3', borderRadius: '4px', padding: '2px', textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>예측상태: {predictPercent}%</Typography>
            </Box>
        </Box>
          <StyledTableContainer component={Paper} sx={{ overflowX: 'hidden', border: '1px solid #d3d3d3', borderRadius: '4px' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ background: '#14a69f', color: '#181d27' }} align="right">우선순위</StyledTableCell>
                <StyledTableCell sx={{ background: '#14a69f', color: '#181d27' }} align="right">검사명</StyledTableCell>
                <StyledTableCell sx={{ background: '#14a69f', color: '#181d27' }} align="right">검사결과</StyledTableCell>
                <StyledTableCell sx={{ background: '#14a69f', color: '#181d27', paddingRight: '15%',  textAlign: 'left' }} align="right">예측결과</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRows.map((row) => (
                <StyledTableRow key={row.examName} >
                  <StyledTableCell sx={{ paddingRight: { xs: '10%', sm: '5%' } }}>{row.no}</StyledTableCell>
                  <StyledTableCell sx={{ paddingRight: { xs: '10%', sm: '5%' } }}>{row.examName}</StyledTableCell>
                  <StyledTableCell sx={{ paddingRight: { xs: '10%', sm: '5%' } }}>{row.examRslt}</StyledTableCell>
                  <StyledTableCell sx={{ paddingRight: '15%' }}>
                  {row.examPredict}
                  (
                  {renderArrowIcon(Number(row.examPredict) - Number(row.examRslt))}
                  {
                    (Number(row.examPredict) - Number(row.examRslt) !== 0) &&

                    (
                      row.examPredict % 1 === 0
                        ? Math.abs(Number(row.examPredict) - Number(row.examRslt)).toFixed(1)
                        : parseFloat(Math.abs(Number(row.examPredict) - Number(row.examRslt)).toFixed(1))
                    ) || ' - '
                  }         
                  )
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
      )
    }
      
    </>
  );
};

export default PatStatePrediction;
