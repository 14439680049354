/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// Dashboard components

import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import { handleValueChange } from '../../routes';
import { PatInfoProvider } from "./PatInfoContext";
import { usePatInfo } from './PatInfoContext';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TreatmentPath from './TreatmentPath';
import TreatmentContainer from "./TreatmentContainer";
import PatStatePrediction from "./PatStatePrediction";
import { useMediaQuery, useTheme } from '@mui/material';
import { CircularProgress } from '@mui/material';

const SamplePage = () => {
  const { patinfo } = usePatInfo();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true); 
  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    // height: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();

  useEffect(() => {
    if(patinfo===null) {
      alert('환자를 선택한 후 치료경로를 예측해주세요.');
      navigate('/TreatmentPath/Score/');
    }
    setSelectedRow(patinfo);
    // 컴포넌트가 언마운트될 때 실행될 cleanup 함수 (optional)
    return () => {
      
    };
  }, [patinfo]); // patinfo가 변경될 때만 실행

  useEffect(() => {
    const initialLoadDelay = setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [])

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            position: 'absolute',
            right: 0, // 맨 오른쪽에 위치
            marginTop: '2%',
          },
        },
      },
    },
  });

  const testTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            position: 'absolute',
            marginTop: '2%'
          },
        },
      },
    },
  });


  const buttonTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'black'
          },
        },
      },
    },
  });

  const [selectedRow, setSelectedRow] = useState(null);
  
  const handleRowSelect = (row) => {
    setSelectedRow(row); // 선택된 행 데이터 업데이트
    setOpen(false); // 모달 열기
  };

  const [showPredictionResults, setShowPredictionResults] = useState(false);
  

  const handlePrediction = () => {
    setShowPredictionResults(true); 
    setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, 200);
  };
  const handleResetPrediction = () => {
    setShowPredictionResults(false);
  };


  const [rows, setRows] = useState('T1');
  const [lastClicked, setLastClicked] = useState('T1');
  const onClickPredict = (title) => {
    console.log('1');
    <CircularProgress />
    // console.log(`Clicked ${title}`);
    if(title === 'T1') {
      setRows('T1');
    }
    else {
      setRows(title);
    }
  };


  
  return (
    <PatInfoProvider>
        {
          loading ?
          (
            <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
              <CircularProgress />
            </MDBox>
          )
          :
          (
            <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={1}> 
            <MDBox>
              <Grid container spacing={3}>
                { loading ? 
                  (<></>)
                  :
                  (
                    <Grid item xs={12}>
                      <Typography variant="h4" sx={{mt:1, mb:2}}>환자 기본정보</Typography>
                        <Card>
                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginTop: 2, marginBottom: 2, marginLeft: 2, backgroundColor : '#ffffff' }}>
                            <TextField
                                label="PatNo"
                                value={selectedRow?.patno}
                                variant="outlined"
                                sx={{ width:'100px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Name"
                                value={selectedRow?.name}
                                variant="outlined"
                                sx={{ width:'110px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Age"
                                value={selectedRow?.age}
                                variant="outlined"
                                sx={{ width:'100px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Sex"
                                value={selectedRow?.sex}
                                variant="outlined"
                                sx={{ width:'100px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="MedDate"
                                value={selectedRow?.meddate}
                                variant="outlined"
                                sx={{ width:'140px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            {/* {'id':2,'name':"홍길동",'patno':11775,'age':55,'sex':'M','meddate':'2023-11-01','LengthofStay':'7days', 'AttendingPhysician': '정윤정', 'Diagnosis':'Sepsis'}, */}
                            <TextField
                                label="Length of Stay"
                                value={selectedRow?.LengthofStay}
                                variant="outlined"
                                sx={{ width:'150px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Attending Physician"
                                value={selectedRow?.AttendingPhysician}
                                variant="outlined"
                                sx={{ width:'180px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Diagnosis"
                                value={selectedRow?.Diagnosis}
                                variant="outlined"
                                sx={{ width:'200px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Medical Department"
                                value={selectedRow?.MedicalDepartment}
                                variant="outlined"
                                sx={{ width:'180px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Inpatient Ward"
                                value={selectedRow?.InpatientWard}
                                variant="outlined"
                                sx={{ width:'200px',margin: 1 }}
                                InputProps={{ readOnly: true }}
                            />
                            </Box>
                        </Card>
                    </Grid>
                  )
                }
              </Grid>
            </MDBox>
            <MDBox mt={4.5}>
              {
                selectedRow === null ? 
                (<></>)
                :
                (
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{mt:1, mb:2}}>검사결과</Typography>
                    <Card>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2, marginBottom: 2, marginLeft: 1 }}>
                            <TextField error sx={{width:'4%', margin: 1, }}label="SBP" value={148} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'4%', margin: 1}}label="HR" value={100} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'4%', margin: 1}}label="BT" value={36.6} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField error sx={{width:'5%', margin: 1}}label="SpO2" value={87} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'6%', margin: 1}}label="LacticAcid" value={1.822} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'8%', margin: 1}}label="Consciousness" value={0.7} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'4%', margin: 1}}label="RR" value={18} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="WBC" value={6.2} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="Plt" value={265} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField error sx={{width:'5%', margin: 1}}label="Cr" value={1.3} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="T.Bil" value={0.9} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="ANC" value={2678.4} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="CRP" value={1.23} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="K" value={4.1} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="HcO3" value={26.4} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="pH" value={7.4} variant="outlined" InputProps={{ readOnly: true }} />
                            <TextField sx={{width:'5%', margin: 1}}label="Hct" value={100} variant="outlined" InputProps={{ readOnly: true }} />
                        </Box>
                      </Card>
                  </Grid>
                )
              }

            </MDBox>


            <MDBox >
              {
                selectedRow === null ? 
                (<></>)
                :
                (
                  <>
                  <Grid container spacing={3} sx={{ mt:1 }}>
                    <Grid item xs={12} md={7} >
                      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>추천 치료경로</Typography>
                      <Card sx={{ height: '730px', maxHeight: '100%', width: '100%', mt:2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1%', width: '100%' }}>
                          <Box sx={{ marginLeft: '2%' }} />
                            <TreatmentPath title='T1' percent='81%' rslt='90%' onClickPredict={onClickPredict} lastClicked={lastClicked} setLastClicked={setLastClicked} />
                            <Box sx={{ marginLeft: '2%' }} />
                            <TreatmentPath title='T2' percent='64%' rslt='90%' onClickPredict={onClickPredict} lastClicked={lastClicked} setLastClicked={setLastClicked} />
                            <Box sx={{ marginBottom: '1%', marginLeft: '2%' }} />
                            <TreatmentPath title='T3' percent='68%' rslt='71%' onClickPredict={onClickPredict} lastClicked={lastClicked} setLastClicked={setLastClicked} />
                            <Box sx={{ marginBottom: '1%', marginLeft: '2%' }} />
                            <TreatmentPath title='T4' percent='38%' rslt='64%' onClickPredict={onClickPredict} lastClicked={lastClicked} setLastClicked={setLastClicked} />
                            <Box sx={{ marginBottom: '1%', marginLeft: '2%' }} />
                            <TreatmentPath title='T5' percent='51%' rslt='80%' onClickPredict={onClickPredict} lastClicked={lastClicked} setLastClicked={setLastClicked} />
                            <Box sx={{ marginRight: '2%' }} />
                          </Box>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <Card sx={{ height: '730px', marginTop: 10 }}>
                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginBottom: 2 }}>
                        <Box sx={{ marginLeft: '2%' }} />
                          <PatStatePrediction state={'예측값'} selectrow={rows}/>
                        <Box sx={{ marginLeft: '2%' }} />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                  </>
                )
              }
            </MDBox>
          </MDBox>
        </DashboardLayout>
          )
        }
      </PatInfoProvider>
  );
}

export default SamplePage;
