import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Box,
    Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import { useEffect, useState } from 'react';

const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            marginTop: '3%',
            '&:focus': {
              backgroundColor: '#14a69f', 
            },
          '&:hover': {
              backgroundColor: '#14a69f', 
            },
          },
        },
      },
    },
  });


function createData(
  order: number,
  item: string,
) {
  return { order, item };
}


const rows = [
  createData(1, 'Arterial'),
  createData(2, 'Central'),
  createData(3, 'Intubation'),
  createData(4, 'ECMO'),
  createData(5, 'CRRT'),
  createData(6, 'ANTI_B'),
  createData(7, 'ANTI_F'),
  createData(8, 'ANTI_V'),
  createData(9, 'VASO'),
  createData(10, 'Fluid'),
  createData(11, 'Trans'),
  createData(12, 'Steroid'),
  createData(13, 'Albumin'),
  createData(14, 'Surgery'),
];
const TreatmentPath = ({ title, percent, rslt, onClickPredict, lastClicked, setLastClicked }) => {
    const [maxHeight, setMaxHeight] = useState('65vh');
    const [headerHeight, setHeaderHeight] = useState(50);
    const selected = lastClicked === title;
    const [buttonColor, setButtonColor] = useState(selected ? '#14a69f' : '#d2d2d2');

  
    useEffect(() => {
        const calculateMaxHeight = () => {
          const innerWidth = window.innerWidth;
          // Adjust maxHeight based on window.innerWidth or other conditions
          const newMaxHeight = innerWidth === 2560 ? '54vh' : '65vh';
          setMaxHeight(newMaxHeight);
        };
    
        // Initial calculation
        calculateMaxHeight();
    
        // Event listener for window resize
        const handleResize = () => {
          calculateMaxHeight();
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

      useEffect(() => {
        setButtonColor(selected ? '#14a69f' : '#d2d2d2');
    }, [selected]);


      const [isT1, setIsT1] = useState(title === 'T1');
      const [isT2, setIsT2] = useState(title === 'T2');
      const [isT3, setIsT3] = useState(title === 'T3');
      const [isT4, setIsT4] = useState(title === 'T4');
      const [isT5, setIsT5] = useState(title === 'T5');


      const handleClick = () => {
        setIsT1(false);
        setIsT2(false);
        setIsT3(false);
        setIsT4(false);
        setIsT5(false);

        if(title === 'T1') {
            setIsT1(true);
        }  if (title === 'T2') {
            setIsT2(true);
        }  if (title === 'T3') {
            setIsT3(true);
        }  if (title === 'T4') {
            setIsT4(true);
        }  if (title === 'T5') {
            setIsT5(true);
        }

        onClickPredict(title);
        setLastClicked(title);
        setButtonColor(selected ? '#14a69f' : '#d2d2d2');
      };
        
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: selected ? '#14a69f' : '#d2d2d2',
          color: selected ? 'white' : '#181d27',
          textAlign: 'center',
          height: '20px',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          textAlign: 'center',
          height: '20px',
        },
        borderRadius: '0',
      }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        backgroundColor: selectByCss(rows) ? '#c2c8d1' : 'inherit',
        color: selectByCss(rows) ? 'white' : 'inherit',
    }));

    const StyledTableContainer = styled(Paper)(({ theme }) => ({
      position: 'relative',
      borderBottomLeftRadius: '2',
      borderBottomRightRadius: '2',
      maxHeight,
      overflow: 'auto',
    }));


    const selectByCss = (row) => {
        if (isT1 && (row.item === 'Fluid')) {
            return true;
        }
        if (isT2 && (row.item === 'Fluid' || row.item === 'Steroid')) {
            return true;
        }
        if (isT3 && (row.item === 'ANTI_B')) {
            return true;
        }
        if (isT4 && (row.item === 'ANTI_B')) {
            return true;
        }
        if (isT5 && (row.item === 'ANTI_B' || row.item === 'Steroid')) {
            return true;
        }
        return false;
    };
      
  
    return (
    <Card sx={{ width: '100%', maxHeight, mt:2, boxShadow: 'none' }}>
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
            <Typography variant="h5" sx={{ textAlign: 'center', m: 0, marginLeft: '5%' }}>{title}</Typography>
            <ThemeProvider theme={theme}>
                <Button variant="contained" value={title} onClick={handleClick} sx={{ backgroundColor: buttonColor }}>분석</Button>
            </ThemeProvider>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
            <Box sx={{ flex: 1, marginRight: 1, border: '1px solid #d3d3d3', borderRadius: '4px', padding: '2px', textAlign: 'center' }}>
              <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>확률: {percent}</Typography>
            </Box>
            <Box sx={{ flex: 1, border: '1px solid #d3d3d3', borderRadius: '4px', padding: '2px', textAlign: 'center' }}>
              <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>예후: {rslt}</Typography>
            </Box>
          </Box>
        </Box>
      <StyledTableContainer component={Paper} sx={{ width: '100%', maxHeight, overflow: 'auto', border: '1px solid #d3d3d3', borderRadius: '1px' }}>
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell className="center-text" colSpan={2} selected={selected}>
                추천치료
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.order} selected={selectByCss(row)}>
                <StyledTableCell component="th" scope="row" selected={selected}>{row.order}</StyledTableCell>
                <StyledTableCell selected={selected}>{row.item}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Card>
    );
  };

export default TreatmentPath;