import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Chart } from "react-google-charts";
// Material Dashboard 2 React examples


function Projects() {
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  const data = [
    ["From", "To", "population", {role: 'tooltip', type: 'string'}],
    ["TazoPERAN Inj", "1차생존", 19500, 'From: TazoPERAN Inj, To: 1차생존'],
    ["Metronidazole Inj", "1차생존", 12750, 'From: Metronidazole Inj, To: 1차생존'],
    ["Meropen Inj", "1차생존", 16750, 'From: Meropen Inj, To: 1차생존'],
    ["TazoPERAN Inj", "1차사망", 500, 'From: TazoPERAN Inj, To: 1차사망'],
    ["Metronidazole Inj", "1차사망", 250, 'From: Metronidazole Inj, To: 1차사망'],
    ["Meropen Inj", "1차사망", 250, 'From: Meropen Inj, To: 1차사망'],
    ["1차생존", "Steroid", 19000, 'From: 1차생존, To: Steroid'],
    ["1차생존", "TazoPERAN Inj1", 10000, 'From: 1차생존, To: TazoPERAN Inj1'],
    ["1차생존", "Metronidazole Inj1", 10000, 'From: 1차생존, To: Metronidazole Inj1'],
    ["1차생존", "Meropen Inj1", 10000, 'From: 1차생존, To: Meropen Inj1'],
    ["Steroid", "2차생존", 17000, 'From: Steroid, To: 2차생존'],
    ["TazoPERAN Inj1", "2차생존", 8000, 'From: TazoPERAN Inj1, To: 2차생존'],
    ["Metronidazole Inj1", "2차생존", 8000, 'From: Metronidazole Inj1, To: 2차생존'],
    ["Meropen Inj1", "2차생존", 8000, 'From: Meropen Inj1, To: 2차생존'],
    ["Steroid", "2차사망", 2000, 'From: Steroid, To: 2차사망'],
    ["TazoPERAN Inj1", "2차사망", 2000, 'From: TazoPERAN Inj1, To: 2차사망'],
    ["Metronidazole Inj1", "2차사망", 2000, 'From: Metronidazole Inj1, To: 2차사망'],
    ["Meropen Inj1", "2차사망", 2000, 'From: Meropen Inj1, To: 2차사망'],
    ["2차생존", "Steroid1", 17000, 'From: 2차생존, To: Steroid1'],
    ["2차생존", "TazoPERAN Inj2", 8000, 'From: 2차생존, To: TazoPERAN Inj2'],
    ["2차생존", "Metronidazole Inj2", 8000, 'From: 2차생존, To: Metronidazole Inj2'],
    ["2차생존", "Meropen Inj2", 8000, 'From: 2차생존, To: Meropen Inj2'],
    ["Steroid1", "사망", 1000, 'From: Steroid1, To: 사망'],
    ["TazoPERAN Inj2", "사망", 1000, 'From: TazoPERAN Inj2, To: 사망'],
    ["Metronidazole Inj2", "사망", 1000, 'From: Metronidazole Inj2, To: 사망'],
    ["Meropen Inj2", "사망", 1000, 'From: Meropen Inj2, To: 사망'],
    ["Steroid1", "3차생존", 16000, 'From: Steroid1, To: 3차생존'],
    ["TazoPERAN Inj2", "3차생존", 7000, 'From: TazoPERAN Inj2, To: 3차생존'],
    ["Metronidazole Inj2", "3차생존", 7000, 'From: Metronidazole Inj2, To: 3차생존'],
    ["Meropen Inj2", "3차생존", 7000, 'From: Meropen Inj2, To: 3차생존'],
    ["1차사망", "2차미조치", 1000, 'From: 1차사망, To: 2차미조치'],
    ["2차미조치", "2차사망", 2000, 'From: 2차미조치, To: 2차사망'],
    ["2차사망", "3차미조치", 10000, 'From: 2차사망, To: 3차미조치'],
    ["3차미조치", "사망", 10000, 'From: 3차미조치, To: 사망']
];
  // sankey chart draw option
  const options = {
    width: "90%",
    height: "100%",
    sankey: {
      node: {
        colors: ["#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c"],
      },
    },
  };
  // servival rate graph text
  

  return (
    <Card>
      <MDBox display="flex" justifyContent="center" alignItems="center" p={5} sx={{ml:2}}>
        <Chart
            chartType="Sankey"
            data={data}
            options={options}
            width="95%"
            height="470px"
            // margin="100px"
            legendToggle
            
          />
      </MDBox>
    </Card>
  );
}

export default Projects;
