// PatInfoContext.js

import React, { createContext, useContext, useState } from 'react';

const PatInfoContext = createContext();

export const PatInfoProvider = ({ children }) => {
  const [patinfo, setPatInfo] = useState(null);

  const handleValueChange = (newPatinfo) => {
    setPatInfo(newPatinfo);
  };

  return (
    <PatInfoContext.Provider value={{ patinfo, handleValueChange }}>
      {children}
    </PatInfoContext.Provider>
  );
};

export const usePatInfo = () => {
  const context = useContext(PatInfoContext);
  if (!context) {
    throw new Error('usePatInfo must be used within a PatInfoProvider');
  }
  return context;
};
